import { createContext, useEffect, useState,useRef,useCallback} from 'react';
import { API, Auth } from "aws-amplify";
// import { getAudioDataAPI } from "../myapi/url";
import { SetAPIConfig } from "./AmplifyContext";
import useIsMountedRef from "../hooks/useIsMountedRef";
// import PropTypes from 'prop-types';
// import { THEMES } from '../constants';


// const initialSettings = {
//   compact: true,
//   direction: 'ltr',
//   responsiveFontSizes: true,
//   roundedCorners: true,
//   theme: THEMES.LIGHT
// };

const DynamoUserTableContext = createContext();
  //{
  // settings: initialSettings,
  // saveSettings: () => { }
  //}

  
//上位の階層で挟む
export const DynamoUserTableProvider = (props) => {

  const { children } = props;
  // const [settings, setSettings] = useState(initialSettings);

  const [dynamoUserTable, setDynamoUserTable] = useState({});
  const isMountedRef = useIsMountedRef();

  const getAudio = useCallback(async () => {
    try {
      SetAPIConfig("dev", process.env.REACT_APP_getAudioDataAPI);
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      const myInitUser = {
        headers: {
          Authorization: token,
        },
        queryStringParameters: {
          OperationType: "QUERY",
          queryType: "GET_USER_DATA",//"GET_DATA",//"GET_USER_DATA"
        },
      };
      //audioデータのリスト取ってくる（dynamodbから）
      const response = await API.get("dev", "/getdata", myInitUser);

      setDynamoUserTable(response.Items)
      console.log("dynamodbget")
      console.log(response)

      
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {

    getAudio()

  },[])




  return (
    <DynamoUserTableContext.Provider
      value={{
        dynamoUserTable,
        setDynamoUserTable,
      }}
    >
      {children}
    </DynamoUserTableContext.Provider>
  );
};

//全て使いたいような状況ではこれで挟めばできる
export const DynamoUserTableContextConsumer = DynamoUserTableContext.Consumer;

export default DynamoUserTableContext;
