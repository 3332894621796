import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth,Storage,API } from 'aws-amplify';
import { amplifyConfig } from '../config';

Amplify.configure(amplifyConfig);

//Configure Storage with S3 bucket information
export function SetS3Config(bucket, level){
  Storage.configure({ 
         bucket: bucket,
         level: level,
         region: 'ap-northeast-1',  
         identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID 
      });
}

export function SetAPIConfig(name, endpoint){
  API.configure({ 
    endpoints:[
      {
        name: name,
        endpoint:endpoint
          // 'https://<APIGATEWAY_ID>execute-api.ap-northeast-1.amazonaws.com/dev',
      }
    ]})
}

//Configure API with apigateway information
  // API.configure({ 
  //   endpoints:[
  //     {
  //       name: 'dev',
  //       endpoint:'https://9ib394m9c3.execute-api.ap-northeast-1.amazonaws.com/dev'
  //         // 'https://<APIGATEWAY_ID>execute-api.ap-northeast-1.amazonaws.com/dev',
  //     }
  //   ]})
// }

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.sub,
              avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
              email: user.attributes.email,
              name: '',
              // plan: 'Premium'
            }
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const user = await Auth.signIn(email, password);
    
    console.log("user")
    console.log(user)
    // console.log(user.signInUserSession.accessToken.payload["cognito:groups"])
    console.log(user.attributes)
    if (user.challengeName) {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // 初回ログイン時は、必ずパスワードを再設定するように求めてくる
        // 新しいパスワードは同じでも構わないので、completeNewPasswordに同じパスワードを渡してしまう
        const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']

        const loggedUser = await Auth.completeNewPassword(user, password);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: {
              id: loggedUser.username, //loggedUser.attributes.sub,
              avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
              email: email,//loggedUser.attributes.email,
              name: '',
              // plan: 'Premium'
            }
          }
        });

        return 
      }
      // console.error(`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`);
      // return;
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.attributes.sub,
          avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
          email: user.attributes.email,
          name: '',
          // plan: 'Premium'
        }
      }
    });
  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email, password) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
