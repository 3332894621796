// import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
// import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
// import gtm from './lib/gtm';
import routes from './routes';
import { createTheme } from './theme';

function App() {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  // useEffect(() => {
  //   gtm.initialize(gtmConfig);
  // }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });


  
  return (
    <>
    <ThemeProvider theme={theme}>
        <SnackbarProvider
          dense
          maxSnack={3}
        >
          <GlobalStyles />
          {/* <SettingsDrawer /> */}
          {/* settingsDrawerは、画面右下にあるボタンの中身 */}
          {auth.isInitialized ? content : <SplashScreen />}
        </SnackbarProvider>
    </ThemeProvider>
    </>
  );
}

export default App;
