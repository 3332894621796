import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
// import './__mocks__';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
// import { Provider as ReduxProvider } from 'react-redux';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import { AuthProvider } from './contexts/JWTContext';
import { AuthProvider } from './contexts/AmplifyContext';
// import { AuthProvider } from './contexts/AmplifyContext';
// import { SettingsProvider } from './contexts/SettingsContext';
// import * as serviceWorker from './serviceWorker';
// import store from './store';



ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* <ReduxProvider store={store}> */}
        <StyledEngineProvider injectFirst>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
            {/* <SettingsProvider> */}
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            {/* </SettingsProvider> */}
          {/* </LocalizationProvider> */}
        </StyledEngineProvider>
      {/* </ReduxProvider> */}
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
