import { useContext } from 'react';
// import AuthContext from '../contexts/JWTContext';
// import AuthContext from '../contexts/FirebaseAuthContext';
// import AuthContext from '../contexts/Auth0Context'
import AuthContext from '../contexts/AmplifyContext'


const useAuth = () => useContext(AuthContext);

export default useAuth;
