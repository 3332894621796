import { Link as RouterLink,useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar,Button, Typography } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuIcon from '../../icons/Menu';
// import AccountPopover from './AccountPopover';
// import ContactsPopover from './ContactsPopover';
import ContentSearch from './ContentSearch';
import Logo from '../Logo';
import NotificationsPopover from './NotificationsPopover';

import useAuth from '../../hooks/useAuth';



const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  //////////////追加/////////////////
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  //////////////////////////////////


  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink style={{ textDecoration: 'none' }} to="/">
            {/* <Logo
              sx={{
                height: 40,
                width: 40
              }}
            /> */}
            <Box>
             <Typography
                      color="white"
                      // sx={{ pl: 1 }}
                      variant="h6"
                    >
                      {'Fonet BoX'}
                      </Typography>
                      </Box>
          </RouterLink>
        </Hidden>
        {/* {<b>&nbsp; Fonet BoX</b>} */}

        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        <Box sx={{ ml: 1 }}>
          <ContactsPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}
        <Box sx={{ ml: 2 }}>
        <Button 
        onClick={handleLogout}
        variant="contained" 
        color="primary">
          Logout
        </Button>
          {/* <AccountPopover /> */}
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
